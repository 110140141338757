.product {
  .card {
    padding: 1rem;
    margin-top: 1rem;
  }
  .variants {
    display: flex;
  }
  .varspace {
    margin-left: 3px;
    margin-right: 3px;
  }
  div.varspace:hover{
    border-color: red;
  }
  .review {
    border-top: 1px solid #ccc;
  }
  .hline {
    margin-top: 20px;
  }
  .details {
    padding-top: 2rem;
    display: flex;

    .img {
      width: 45%;
      border: 1px solid #ccc;
      border-radius: 3px;
      img {
        width: 100%;
      }
    }

    .content {
      width: 55%;
      padding: 0 25px;
      & > * {
        margin-bottom: 1rem;
      }
      .price {
        color: orangered;
        font-weight: 500;
      }

      .count {
        display: flex;
        align-items: center;
        & > * {
          margin-right: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .product {
    .details {
      flex-direction: column;

      .img {
        width: 100%;
      }

      .content {
        width: 100%;
      }
    }
  }
}
